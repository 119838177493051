<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
      </div>

      <!-- page header: title -->
      <div class="all-center">
        <h3 class="main--text">{{ translations.admin }}</h3>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <div class="all-center" style="width: 20%">
        </div>

        <div class="all-center" style="width: 60%">
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <div class="page-content pl-4">

        <!-- recalculate report -->
        <div class="admin-title">Reports</div>
        <div class="admin-box">
          <v-menu v-model="dateFromShow" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateFromLabel" :placeholder="translations.dateFrom" v-bind="attrs" v-on="on"
                            readonly clearable hide-details dense
                            @click:clear="() => onDateFromChange(null)"/>
            </template>
            <v-date-picker v-model="dateFrom" :max="dateTo" locale="it"
                           @change="value => onDateFromChange(value)"/>
          </v-menu>

          <v-menu v-model="dateToShow" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateToLabel" :placeholder="translations.toDate" v-bind="attrs" v-on="on"
                            readonly clearable hide-details dense
                            @click:clear="() => onDateToChange(null)"/>
            </template>
            <v-date-picker v-model="dateTo" :min="dateFrom" locale="it"
                           @change="value => onDateToChange(value)"/>
          </v-menu>

          <v-btn :loading="reportButtonLoading" :disabled="!dateFrom || !dateTo"
                 color="main" class="white--text" small
                 @click="updateReports">
            Recalculate reports
          </v-btn>
        </div>

        <!-- recalculate timelines -->
        <div class="admin-title">Timelines</div>
        <div class="admin-box">
          <v-btn :loading="recalculateWarningsButtonLoading" @click="recalculateTimelines" color="main" class="white--text" small>
            Recalculate timelines
          </v-btn>
        </div>
      </div>
    </v-card>
  </main>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'
import updateAllEmployeeDailyReports from '@/graphql/UpdateAllEmployeeDailyReports.gql'
import recalculateWarningsQuery from '@/graphql/RecalculateWarningsQuery.gql'

export default {
  data() {
    return {
      translations: translations,

      dateFrom: null,
      dateFromShow: false,
      dateFromLabel: null,
      dateTo: null,
      dateToShow: false,
      dateToLabel: null,
      reportButtonLoading: false,
      recalculateWarningsButtonLoading: false
    }
  },

  mixins: [MomentMixin],

  methods: {
    onDateFromChange(date) {
      this.dateFrom = date
      this.dateFromLabel = this.formatFE(date)
    },

    onDateToChange(date) {
      this.dateTo = date
      this.dateToLabel = this.formatFE(date)
    },

    async updateReports() {
      this.reportButtonLoading = true
      await this.$apollo.mutate({
        mutation: updateAllEmployeeDailyReports,
        variables: {
          dateStrFrom: this.dateFrom,
          dateStrTo: this.dateTo
        }
      }).finally(() => {
        this.reportButtonLoading = false
        this.$root.vtoast.show({ message: translations.updated })
      })
    },

    async recalculateTimelines() {
      this.recalculateWarningsButtonLoading = true
      await this.$apollo.mutate({
        mutation: recalculateWarningsQuery
      }).finally(() => {
        this.recalculateWarningsButtonLoading = false
        this.$root.vtoast.show({ message: translations.updated })
      })
    }
  }
}
</script>


<style>
.admin-title {
  padding-top: 30px;
  font-weight: bold;
}

.admin-box {
  display: grid;
  grid-template-columns: repeat(3, 15%);
  column-gap: 1%;
}
</style>
